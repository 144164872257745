import { Component, For, Show } from "solid-js";

import { hstack } from "#style/patterns";
import { styled } from "#style/jsx";
import { css } from "#style/css";

import { Field, Tags } from "./Field";
import { useTranslation } from "#root/domain/i18n";

type SwitchFieldProps = {
  tags?: Tags[];
  label: string;
  name: string;
  optional?: boolean;
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

export const SwitchField: Component<SwitchFieldProps> = (
  props: SwitchFieldProps,
) => {
  const { t } = useTranslation();
  const checkboxId = () => props.name + "_id_checkbox";

  return (
    <Field.Container
      class={hstack({ justifyContent: "flex-start", alignItems: "center" })}
    >
      <Field.Label
        for={checkboxId()}
        class={css({
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          position: "relative",
          userSelect: "none",
          cursor: "pointer",
          pl: "36px",
          minH: "24px",
          _hover: {
            "& input ~ .checkmark": {
              backgroundColor: "amber.100",
            },
          },
          "& input:checked ~ .checkmark": {
            backgroundColor: "yellow",
          },
          "& input:checked ~ .checkmark:after": {
            display: "block",
          },
          "& .checkmark:after": {
            left: "7px",
            top: "3px",
            width: "8px",
            height: "14px",
            border: "solid",
            borderColor: "black",
            borderWidth: "0 3px 3px 0",
            transform: "rotate(45deg)",
          },
        })}
      >
        {props.label}
        <styled.input
          id={checkboxId()}
          name={props.name}
          type="checkbox"
          checked={props.value}
          onChange={() => props.onChange?.(!props.value)}
          disabled={props.disabled}
          position="absolute"
          top="0"
          left="0"
          height="0"
          width="0"
        />
        <styled.span
          class="checkmark"
          position="absolute"
          top="50%"
          left="0"
          height="24px"
          width="24px"
          backgroundColor="white"
          border="1px solid"
          borderRadius="sm"
          transform="translateY(-50%)"
          _after={{
            content: "' '",
            position: "absolute",
            display: "none",
          }}
        ></styled.span>

        <Show when={props.tags}>
          {(tags) => (
            <For each={tags()}>
              {(tag) => <Field.Tag>{t(`common.fieldTags.${tag}`)}</Field.Tag>}
            </For>
          )}
        </Show>
      </Field.Label>
    </Field.Container>
  );
};
