import { Box } from "#style/jsx";
import { ParentProps } from "solid-js";
import { Typography } from "./Typography";
import { css } from "#style/css";

type AlertProps = ParentProps<{
  type: "error";
}>;

export function Alert(props: AlertProps) {
  return (
    <Box backgroundColor="red.200" p="4" borderRadius="sm" w="full">
      <Typography textStyle="xsmall" class={css({ color: "red.800" })}>
        {props.children}
      </Typography>
    </Box>
  );
}
