import { createEffect, Show } from "solid-js";
import { createStore } from "solid-js/store";
import { format } from "date-fns";
import { useNavigate } from "@solidjs/router";

import { hstack, vstack } from "#style/patterns";
import { Box } from "#style/jsx";

import { Modal } from "#root/components/Modal";
import { Alert } from "#root/components/Alert";
import { Button } from "#root/components/Button";
import { clickOutside } from "#root/module/html";
import { CsrfForm } from "#root/components/CsrfForm";
import { Tags } from "#root/components/Field";
import { InputField } from "#root/components/InputField";
import { SwitchField } from "#root/components/SwitchField";
import { useCreateSession } from "#root/domain/session";
import { useTranslation } from "#root/domain/i18n";
import { isExperimentalUser, useAuthenticatedUser } from "#root/domain/user";

// Workaround to keep the import of the directive
false && clickOutside;

export default function NewSessionModalPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const todayDate = format(Date.now(), "PPP");
  const [form, setFormValue] = createStore({
    sessionName: `${todayDate} Q/A`,
    aiEnabled: false,
  });
  const { createSession, errors } = useCreateSession();
  const user = useAuthenticatedUser();

  function navigateBack() {
    navigate("/app/dashboard");
  }

  function onSubmitNewSession(event: SubmitEvent) {
    event.preventDefault();
    createSession.mutate({ name: form.sessionName, aiEnabled: form.aiEnabled });
  }

  createEffect(() => {
    if (createSession.isSuccess) {
      navigate(`/app/sessions/${createSession.data.id}`);
    }
  });

  return (
    <Modal.Root
      open
      onBackdropClicked={navigateBack}
      onClose={navigateBack}
      header={<Modal.Header title={t("newSession.pageTitle")} />}
      footer={
        <Modal.Footer
          class={hstack({
            gap: "4",
            justifyContent: "flex-end",
          })}
        >
          <Button
            tag="button"
            type="submit"
            form="sessionForm"
            color="yellow"
            isLoading={createSession.isPending}
          >
            {t("newSession.form.start")}
          </Button>
          <Button
            tag="a"
            href="/app/dashboard"
            color="darkGray"
            status="secondary"
          >
            {t("newSession.form.close")}
          </Button>
        </Modal.Footer>
      }
    >
      <Modal.Content>
        <Show when={errors()?.global}>
          {(globalError) => (
            <Box mb="4" w="full">
              <Alert type="error">{globalError()}</Alert>
            </Box>
          )}
        </Show>
        <CsrfForm
          class={vstack({ w: "full", gap: "4" })}
          onSubmit={onSubmitNewSession}
          id="sessionForm"
        >
          <InputField
            name="name"
            type="text"
            optional
            label={t("newSession.form.nameLabel")}
            value={form.sessionName}
            onChange={(v) => setFormValue("sessionName", v)}
          />
          <Show when={isExperimentalUser(user)}>
            <SwitchField
              tags={[Tags.New, Tags.Experimental]}
              name="ai"
              label={t("newSession.form.aiLabel")}
              optional
              value={form.aiEnabled}
              onChange={(v) => setFormValue("aiEnabled", v)}
            />
          </Show>
        </CsrfForm>
      </Modal.Content>
    </Modal.Root>
  );
}
